








































import Vue from 'vue'
import { mapState } from 'vuex'
import { dateTimeFormats } from '../../translations/dateTimeLocale'
const DAY = 24 * 60 * 60 * 1000
const WEEK = DAY * 6
export default Vue.extend({
  name: 'CalendarFilter',
  props: {
    value: Array,
    isEmpty: Boolean,
  },
  data() {
    return {
      dates: this.value,
      dateLocale: dateTimeFormats[this.$i18n.locale],
      toggled: {},
    }
  },
  watch: {
    value(val) {
      this.dates = val
    },
    dates(val: Date[]) {
      const filters = { ...this.filters }
      this.toggled = {}
      if (val[1] !== null) {
        filters.dateCreated = [
          `${val[0].toISOString()}..${new Date(
            val[1].setHours(23, 59, 59, 999),
          ).toISOString()}`,
        ]
      }
      this.$store.commit('setValue', ['filters', filters])
    },
  },
  computed: {
    ...mapState(['filters']),
    datesButtons(): any[] {
      return [
        {
          label: this.$t('calendarButtons.yesterday'),
          onClick: this.yesterday,
        },
        {
          label: this.$t('calendarButtons.today'),
          onClick: this.today,
        },
        {
          label: this.$t('calendarButtons.week'),
          onClick: this.lastWeek,
        },
        {
          label: this.$t('calendarButtons.month'),
          onClick: this.lastMonth,
        },
      ]
    },
  },
  methods: {
    setToggledButton(label: any) {
      setTimeout(() => {
        this.toggled = label
      })
    },
    today() {
      this.dates = [new Date(new Date().setHours(0, 0, 0, 0)), new Date()]
    },
    yesterday() {
      const today = new Date()
      const yesterday = new Date(today.getTime() - DAY)
      this.dates = [
        new Date(yesterday.setHours(0, 0, 0, 0)),
        new Date(yesterday.setHours(23, 59, 59, 999)),
      ]
    },
    lastWeek() {
      const today = new Date(new Date().setHours(0, 0, 0, 0))
      this.dates = [new Date(today.getTime() - WEEK), today]
    },
    lastMonth() {
      const today = new Date()
      const milisecondsInCurrentMonth =
        new Date(today.setMonth(today.getMonth(), 0)).getDate() * DAY - DAY
      this.dates = [
        new Date(
          new Date(new Date().getTime() - milisecondsInCurrentMonth).setHours(
            0,
            0,
            0,
            0,
          ),
        ),
        new Date(),
      ]
    },
    clearDate() {
      this.dates = [new Date(), null]
      this.toggled = {}
      const filters = { ...this.filters }
      delete filters.dateCreated
      this.$store.commit('setValue', ['filters', filters])
    },
  },
})
