var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"archive-page"}},[_c('div',{ref:"container",staticClass:"list-container"},[_c('div',{staticClass:"archive__header",style:({
        'border-bottom': _vm.loading
          ? '1px solid transparent'
          : '1px solid var(--text-color-secondary)',
      })},[_c('div',{staticClass:"arсhive__title",style:({
          'border-bottom': _vm.loading
            ? '4px solid transparent'
            : '4px solid var(--primary-color)',
        }),attrs:{"data-cy":"arсhive-title"}},[_c('span',{domProps:{"textContent":_vm._s(_vm.currentFolderName || _vm.$t('recordsArchive'))}})]),_c('ProgressBar',{style:({
          height: 'calc(2rem / var(--bfs))',
          opacity: _vm.loading ? 1 : 0,
        }),attrs:{"mode":"indeterminate"}})],1),(_vm.count > 0 || _vm.nothingFound || !_vm.isRootFolder)?_c('div',{staticClass:"archive__actions",attrs:{"data-cy":"archive-actions"}},[_c('div',[_c('CalendarFilter',{attrs:{"data-cy":"calendar-filter","isEmpty":_vm.nothingFound},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('Checkbox',{staticClass:"p-mr-1",class:{ 'empty-checkbox': _vm.onlyMine && _vm.nothingFound },attrs:{"data-cy":"сheckbox-only-mine","binary":true},model:{value:(_vm.onlyMine),callback:function ($$v) {_vm.onlyMine=$$v},expression:"onlyMine"}}),_c('span',{staticClass:"p-mr-3",staticStyle:{"color":"var(--text-color)","font-weight":"500"},domProps:{"textContent":_vm._s(_vm.$t('button.onlyMineFilter'))}}),_c('div',{staticStyle:{"flex-grow":"1"}}),(_vm.groupMode)?_c('div',{staticClass:"p-d-flex"},[_c('Button',{staticClass:"p-button-outlined p-mr-2",attrs:{"label":_vm.$t('button.selectAll'),"data-cy":"button-select-all"},on:{"click":_vm.selectAll}}),_c('Button',{staticClass:"p-button-outlined p-button-secondary p-mr-2",attrs:{"label":_vm.$t('button.unselect'),"data-cy":"button-cancel-select-all"},on:{"click":_vm.unselect}}),_c('Cut',{staticClass:"p-mr-2"}),_c('Button',{staticClass:"p-button-outlined p-button-secondary p-mr-2",attrs:{"label":_vm.$t('button.cancel'),"data-cy":"button-cancel-select-mode"},on:{"click":function () {
              _vm.$store.commit('setValue', ['groupMode', false])
              _vm.unselect()
            }}}),_c('Delete')],1):_c('div',{staticClass:"p-d-flex"},[(_vm.cuttedEntities.length !== 0)?_c('Paste',{staticClass:"p-mr-2",attrs:{"isMain":"","entity":{
            id:
              _vm.filters.parentFolderId[0] === 'null'
                ? null
                : _vm.filters.parentFolderId[0],
            title: _vm.currentFolderName,
          }}}):_vm._e(),(_vm.isRootFolder && _vm.canI('CreateRecordFolder'))?_c('CreateFolder',{staticClass:"p-mr-2",attrs:{"data-cy":"button-create-folder"}}):_vm._e(),(_vm.canI('CreateFile'))?_c('UploadFile',{staticClass:"p-mr-2",attrs:{"data-cy":"button-upload-file","parentFolderId":_vm.filters.parentFolderId[0] || null}}):_vm._e(),_c('AccessCode',{attrs:{"data-cy":"button-access-code"}})],1)],1):_vm._e(),(_vm.filters.title !== '' && _vm.count > 0 && !_vm.loading)?_c('div',{staticClass:"p-mb-2",staticStyle:{"position":"relative"}},[_c('span',{domProps:{"textContent":_vm._s(((_vm.$t('finded')) + ": " + _vm.count))}})]):_vm._e(),((!_vm.nothingFound && !_vm.isRootFolder) || _vm.count > 0)?_c('div',{staticClass:"archive__content"},[_c('transition-group',{staticClass:"cards",attrs:{"name":"list-complete","tag":"div","data-cy":"all-cards"}},[(!_vm.isRootFolder)?_c('div',{key:"back-button",staticClass:"card card_draggable card_droppable list-complete-item card-back",attrs:{"data-id":null,"data-title":_vm.$t('rootFolder')},on:{"click":_vm.closeFolder}},[_c('img',{staticStyle:{"position":"relative","right":"15%"},attrs:{"src":require("@/assets/back-icon.svg"),"height":"70","width":"232"}}),_c('span',{staticClass:"p-mt-3",domProps:{"textContent":_vm._s(_vm.$t('button.back'))}})]):_vm._e(),_vm._l((_vm.entities),function(entity){return _c('CardFactory',{key:entity.id,staticClass:"card list-complete-item",attrs:{"entity":entity,"data-cy":"card"}})}),_vm._l((_vm.loading && _vm.showSkeleton && _vm.count === 0
            ? _vm.limit
            : 0),function(_,index){return _c('div',{key:("skeleton-" + index),staticClass:"card list-complete-item"},[_c('span',{staticClass:"skeleton-box"})])})],2)],1):_vm._e(),(_vm.nothingFound)?_c('EmptySearch',{attrs:{"isRootFolder":_vm.isRootFolder},on:{"closeFolder":_vm.closeFolder}}):_vm._e(),(_vm.count === 0 && !_vm.loading && _vm.isRootFolder && !_vm.nothingFound)?_c('EmptyArchive'):_vm._e(),_c('div',{staticClass:"archive__paginator"},[(_vm.count > 0 || _vm.nothingFound || !_vm.isRootFolder)?_c('TypeFilters',{attrs:{"isEmpty":_vm.nothingFound && _vm.entityTypes.length > 0},model:{value:(_vm.entityTypes),callback:function ($$v) {_vm.entityTypes=$$v},expression:"entityTypes"}}):_vm._e(),(_vm.count > 0)?_c('div',[_c('Paginator',{attrs:{"first":_vm.offset,"rows":_vm.limit,"totalRecords":_vm.count,"template":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink","data-cy":"paginator-archive"},on:{"update:first":function($event){_vm.offset=$event},"update:rows":function($event){_vm.limit=$event}},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('Dropdown',{staticClass:"p-inputwrapper-fille",staticStyle:{"margin-right":"0"},attrs:{"options":Array.from(new Set([6, 8, 10, 12, 15, 18, 21, _vm.limit])).sort(
                  function (a, b) { return a - b; }
                ),"appendTo":"body"},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}})]},proxy:true},{key:"right",fn:function(){return [_c('Button',{staticClass:"p-button-blurred",attrs:{"type":"button","icon":"mdi mdi-18px mdi-refresh"},on:{"click":_vm.initData}})]},proxy:true}],null,false,3472445432)})],1):_vm._e(),_c('div',{staticStyle:{"width":"100px"}})],1)],1),_c('div',{attrs:{"syle":"display: none;"}},[_c('v-teleport',{attrs:{"to":"searchTeleport"}},[_c('div',{staticClass:"entity__search"},[_c('span',{staticClass:"p-input-filled p-input-icon-left",staticStyle:{"backdrop-filter":"blur(5px)","width":"100%"}},[_c('i',{staticClass:"mdi mdi-18px mdi-magnify"}),_c('InputText',{class:{
              'p-error':
                !_vm.loading && _vm.entities.length === 0 && _vm.searchTitle !== '',
            },staticStyle:{"width":"100%"},attrs:{"type":"text","placeholder":_vm.$t('search')},model:{value:(_vm.searchTitle),callback:function ($$v) {_vm.searchTitle=$$v},expression:"searchTitle"}})],1),(_vm.searchTitle !== '')?_c('i',{staticClass:"mdi mdi-18px mdi-close close-button",on:{"click":function () {
              _vm.searchTitle = ''
              _vm.loading = true
            }}}):_vm._e()])])],1),_c('PasteToFolder',{attrs:{"targetFolder":_vm.targetFolder}}),_c('Toast',{attrs:{"data-cy":"toast-alert","position":"bottom-center"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }