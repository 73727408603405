














import Vue from 'vue'

export default Vue.extend({
  name: 'Paste',
  props: {
    entity: Object,
    isMain: Boolean,
  },
  methods: {
    pasteHere() {
      this.$store.commit('setValue', [
        'targetFolder',
        { id: this.entity.id, title: this.entity.title },
      ])
    },
  },
})
