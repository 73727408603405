import { mapState } from 'vuex'
import { createCamerasConnection } from '@netvision/lib-api-gateway'

const initialState = () =>
  ({
    spaParent: {} as SpaParent,
    widgetProps: {} as WidgetProps,
    myId: '',
    filters: { parentFolderId: ['null'], title: '', owner: '' } as Record<
      string,
      string | string[]
    >,
    allAssignmentGroups: [] as AssignmentGroup[],
    allAssignments: [] as Assignment[],
    permissionScopes: new Map(),
    currentFolderName: '',
    currentRoutePath: '',
    entities: [] as ArchiveEntities[],
    loading: true,
    cuttedEntities: [] as (CameraRecord | UploadedFile)[],
    selectedEntities: [] as ArchiveEntities[],
    sharedEntitiesIds: [] as string[],
    groupMode: false,
    MAX_NAME_LENGTH: 50,
    targetFolder: {
      id: '' as string | null,
      title: '',
    },
  } as const)

type State = ReturnType<typeof initialState>
type actionArguments = {
  commit: <T extends keyof State>(
    method: string,
    payload: [T, State[T]],
  ) => void
  state: State
  dispatch: (arh: actionArguments, payload: any) => void
}
export const mapStateTyped = <
  T extends keyof State,
  G extends { [Key in T]: () => State[Key] }
>(
  keys: T[],
): G => {
  return { ...mapState(keys) } as G
}

export default {
  state: initialState,
  mutations: {
    setValue(state: State, [key, value]: [string, any]) {
      // @ts-ignore
      state[key] = value
    },
    reset(state: State) {
      const s = initialState()
      Object.keys(s).forEach((key) => {
        // @ts-ignore
        state[key] = s[key]
      })
    },
  },
  actions: {
    async fetchAssignmentGroupsAndAssignments({ commit }: actionArguments) {
      const {
        results: groups,
      } = await createCamerasConnection().v2.listEntities({
        type: 'AssignmentGroup',
        keyValues: true,
        limit: 1000,
      })
      commit('setValue', ['allAssignmentGroups', groups || []])
      const {
        results: assignments,
      } = await createCamerasConnection().v2.listEntities({
        type: 'Assignment',
        keyValues: true,
        limit: 1000,
      })
      commit('setValue', ['allAssignments', assignments || []])
    },
  },
}
