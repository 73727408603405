





























import Mixins from '../common/Mixins'
import { createCamerasConnection } from '@netvision/lib-api-gateway'
import { mapStateTyped } from '@/store'

export default Mixins.extend({
  name: 'PasteToFolder',
  data() {
    return {}
  },

  computed: {
    ...mapStateTyped(['cuttedEntities', 'targetFolder']),
    visible: {
      get(): boolean {
        return this.targetFolder.id !== ''
      },
      set(val: boolean) {
        if (!val) {
          this.$store.commit('setValue', [
            'targetFolder',
            { ...this.targetFolder, id: '' },
          ])
          this.$store.commit('setValue', ['cuttedEntities', []])
        }
      },
    },
  },
  methods: {
    pasteInFolder() {
      const promises = this.cuttedEntities.map(
        ({ id, type }: { id: string; type: string }) => {
          return createCamerasConnection()
            .v2.updateEntityAttributes(
              {
                id,
                parentFolderId: this.targetFolder.id || null,
                type,
              },
              {
                keyValues: true,
              },
            )
            .catch(this.errorToast)
        },
      )
      Promise.allSettled(promises).then(() => {
        this.$store.commit('setValue', ['cuttedEntities', []])
        this.$eventBus.$emit('updateEntity')
        this.visible = false
      })
    },
  },
})
