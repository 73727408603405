var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-d-flex",staticStyle:{"margin":"0 0 10px -0.5rem","width":"calc(100% + 1.5rem)"}},[_c('div',[_c('Button',{staticClass:"p-button-rounded p-button-plain p-button-text",staticStyle:{"flex-shrink":"0","color":"var(--text-color)"},attrs:{"icon":"mdi mdi-24px mdi-plus-circle-outline","title":_vm.$t('message.addAssignmentGroup')},on:{"click":function () {
          _vm.currentAssignmentGroup = { id: null }
          _vm.toggleAssignmentGroupEditor()
        }}})],1),_c('div',{attrs:{"id":("scrollPanel-" + (_vm.entity.id))}},[_c('ScrollPanel',_vm._l((_vm.entityAssignmnetGroups),function(assignmnetGroup){return _c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(assignmnetGroup.title),expression:"assignmnetGroup.title",modifiers:{"top":true}}],key:assignmnetGroup.id,staticClass:"p-button-rounded p-button-text",class:[
          assignmnetGroup.status !== 'Started' ? 'p-button-plain' : '' ],staticStyle:{"flex-shrink":"0"},attrs:{"icon":("mdi mdi-24px " + (_vm.loadingAssignmentId === assignmnetGroup.id
              ? 'mdi-spin mdi-loading'
              : _vm.getGroupIcon(assignmnetGroup)))},on:{"click":function () {
            _vm.currentAssignmentGroup = assignmnetGroup
            _vm.toggleAssignmentGroupEditor()
          }}})}),1)],1),_c('div',{staticStyle:{"flex-grow":"1"}}),_c('div',[_c('Button',{staticClass:"p-button-plain p-button-text",staticStyle:{"font-size":"calc(16rem / var(--bfs))"},attrs:{"label":_vm.$t('button.events')},on:{"click":_vm.toggleEventsPlayer}})],1),_c('OverlayPanel',{ref:(_vm._uid + "assignmentGroup"),staticClass:"fullheight-overlay",attrs:{"position":"full","appendTo":"body"}},[_c('div',[(_vm.displayAssignmentEditor)?_c('AssignmentGroupEditor',{attrs:{"entity":_vm.entity,"assignmentGroup":_vm.currentAssignmentGroup,"toggleAssignmentGroupEditor":_vm.toggleAssignmentGroupEditor}}):_vm._e()],1)]),_c('OverlayPanel',{ref:(_vm._uid + "eventsPlayer"),staticClass:"fullheight-overlay",attrs:{"position":"full","appendTo":"body"}},[_c('div',[(_vm.displayEventsPlayer)?_c('EventsPlayer',{attrs:{"entity":_vm.entity,"toggleEventPlayer":_vm.toggleEventsPlayer}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }