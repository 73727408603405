
























































// @ts-ignore
import { createCamerasConnection } from '@netvision/lib-api-gateway'
import Mixins from '@/components/common/Mixins'
import { mapState } from 'vuex'
const strictRegEx = /[^a-zA-Z0-9а-яА-Я-.,\s]/g

export default Mixins.extend({
  name: 'Rename',
  props: {
    entity: Object as () => ArchiveEntities,
  },
  data() {
    return {
      visible: false,
      currentName: this.entity.title || '',
    }
  },
  computed: {
    ...mapState(['MAX_NAME_LENGTH']),
  },
  watch: {
    visible(val) {
      if (val) {
        setTimeout(() => {
          const input = document.getElementById(
            `input-${this._uid}`,
          ) as HTMLInputElement
          if (input) {
            input.focus()
          }
        })
      }
    },
    currentName(val: string) {
      this.currentName = val
        .replace(strictRegEx, '')
        .slice(0, this.MAX_NAME_LENGTH)
    },
  },
  methods: {
    changeName(): PromiseConstructor {
      const connection = createCamerasConnection()
      this.currentName = this.currentName.trim()
      const method =
        this.entity.title === undefined
          ? 'appendEntityAttributes'
          : 'updateEntityAttributes'
      return connection.v2[method](
        {
          type: this.entity.type,
          id: this.entity.id,
          title: this.currentName,
        },
        {
          keyValues: true,
        },
      )
        .then(async () => {
          this.visible = false
          this.entity.title = this.currentName
        })
        .catch(this.errorToast)
    },
  },
})
