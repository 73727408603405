




















import ErrorToastMixin from './ErrorToastMixin'
import { createCamerasConnection } from '@netvision/lib-api-gateway'

type Option = { label: string; value: string }
export default ErrorToastMixin.extend({
  name: 'MultiSelectPermissions',
  props: {
    permissionsByRequesters: Object,
    entity: Object as () => ArchiveEntities,
    user: Object as () => User,
    permissionOptions: Array as () => Option[],
  },
  data() {
    return {
      readPermission: `Read${this.entity.type}`,
      loading: false,
      multiSelect: {} as any,
    }
  },
  computed: {
    currentPermissions: {
      get(): string[] {
        return this.userPermissions.map((e: any) => e.scope)
      },
      set(newVal: string[]) {
        if (!this.loading) {
          this.loading = true
          const addedVal = newVal.filter(
            (val) => !this.currentPermissions.includes(val),
          )
          if (addedVal.length) {
            if (
              !addedVal.includes(this.readPermission) &&
              !this.currentPermissions.includes(this.readPermission)
            ) {
              this.createPermissionTicket([
                ...this.currentPermissions,
                ...addedVal,
                this.readPermission,
              ])
            } else {
              this.createPermissionTicket(addedVal)
            }
          } else {
            const deletedVal = this.currentPermissions.filter(
              (val) => !newVal.includes(val),
            )
            if (deletedVal.length) {
              if (deletedVal.includes(this.readPermission)) {
                this.deletePemissionTicket(this.currentPermissions)
              } else {
                this.deletePemissionTicket(deletedVal)
              }
            }
          }
        }
      },
    },
    userPermissions(): { id: string; scope: string }[] {
      return this.permissionsByRequesters[this.user.id] || []
    },
  },
  methods: {
    createPermissionTicket(scopes: string[]) {
      Promise.allSettled(
        scopes.map((scope) =>
          createCamerasConnection()
            .v2.createEntity(
              {
                type: 'PermissionTicket',
                id: '',
                scope,
                requester: this.user.id,
                entityId: this.entity.id,
              },
              {
                keyValues: true,
              },
            )
            .catch(this.errorToast),
        ),
      ).then(() => {
        this.loading = false
        this.$emit('updateTickets')
      })
    },
    deletePemissionTicket(scopes: string[]) {
      Promise.allSettled(
        scopes.map((scope) => {
          const permission = this.userPermissions.find(
            (permission: any) => permission.scope === scope,
          )
          if (permission) {
            return createCamerasConnection()
              .v2.deleteEntity({
                type: 'PermissionTicket',
                id: permission.id,
              })
              .catch(this.errorToast)
          }
          return new Promise(() => {})
        }),
      ).then(() => {
        this.loading = false
        this.$emit('updateTickets')
      })
    },
  },
  mounted() {
    this.multiSelect = this.$refs.container
  },
})
