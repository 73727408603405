
















































import Mixin from '../Mixins'
import { createCamerasConnection } from '@netvision/lib-api-gateway'
const codeRegEx = /[^a-zA-Z0-9/-]/g

export default Mixin.extend({
  name: 'AccessCode',
  props: {
    entity: Object,
    isLarge: {
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      accessCode: '',
    }
  },
  watch: {
    visible(val) {
      if (val) {
        setTimeout(() => {
          const input = document.getElementById(
            `input-${this._uid}`,
          ) as HTMLInputElement
          if (input) {
            input.focus()
          }
        })
      }
    },
    accessCode(val) {
      const clearedVal = val.replace(codeRegEx, '')
      if (this.accessCode.length !== clearedVal.length) {
        this.accessCode = clearedVal
      }
    },
  },
  methods: {
    acceptCode() {
      createCamerasConnection()
        .v2.updateEntityAttributes(
          {
            type: 'PermissionAccessCode',
            id: this.accessCode,
            accepted: [this.myId],
          },
          {
            keyValues: true,
          },
        )
        .then(() => {
          this.accessCode = ''
          this.visible = false
          this.$eventBus.$emit('updateEntity')
        })
        .catch((error: any) => {
          console.log(error)
          this.errorToast({ message: this.$t('accessCodeError') })
        })
    },
  },
})
