import ErrorToastMixin from './ErrorToastMixin'
import { mapState } from 'vuex'
import { createCamerasConnection } from '@netvision/lib-api-gateway'
// @ts-ignore
const { url, headers } = createCamerasConnection()
const typeEndPoint = {
  Record: 'records',
  File: 'files',
} as Record<string, string>

export default ErrorToastMixin.extend({
  name: 'Mixins',
  data() {
    return {
      inputTimer: {} as any,
    }
  },
  computed: {
    ...mapState([
      'permissionScopes',
      'currentFolderName',
      'filters',
      'myId',
      'movingObject',
      'entities',
    ]),
  },
  methods: {
    canI(scope: string, id: string | null = null): boolean {
      const scopes = this.permissionScopes.get(id)
      return Array.isArray(scopes) ? scopes.includes(scope) : false
    },
    download(entity: ArchiveEntities) {
      const endPoint = typeEndPoint[entity.type] || 'records'
      // @ts-ignore
      return fetch(url.href + `v1/${endPoint}/${entity.id}`, { headers })
        .then(async (response: any) => {
          const fileLocaltion = response.headers.get('Location')
          window.open(url + fileLocaltion.slice(1), '_blank')
        })
        .catch(() => this.errorToast({ message: this.$t('downloadError') }))
    },
    menuToggle(ref: string, event: Event) {
      let menu = this.$refs[ref] as Vue & {
        toggle: (event: Event) => {}
      }
      menu = Array.isArray(menu) ? menu[0] : menu
      if (menu !== undefined) {
        menu!.toggle(event)
      }
    },
    formatBytes(bytesString: string, decimals = 2) {
      const bytes = Number(bytesString)

      if (bytes === 0) return `0 ${this.$t('fileSizes.Bytes')}`
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${
        sizes[i] ? this.$t(`fileSizes.${sizes[i]}`) : ''
      }`
    },
    errorToast(error: any) {
      console.error(error)
      this.$toast.add({
        severity: 'error',
        summary: this.$t('error'),
        detail: error.message,
        life: 5000,
      })
    },
    insensitify(str: string) {
      return str
        .split('')
        .map((char) => `[${char.toLowerCase()}${char.toUpperCase()}]`)
        .join('')
    },
    requestDelay(condition: boolean, callback: Function) {
      if (condition) {
        clearTimeout(this.$data.inputTimer)
        this.$data.inputTimer = setTimeout(callback, 1000)
      } else {
        clearTimeout(this.$data.inputTimer)
      }
    },
  },
})
