export const RUSSIAN_TRANSLATIONS = {
  button: {
    ok: 'ОК',
    yes: 'Да',
    no: 'Нет',
    delete: 'Удалить',
    save: 'Сохранить',
    create: 'Создать',
    cancel: 'Отмена',
    done: 'Готово',
    createAccessCode: 'Создать код',
    deleteAccessCode: 'Удалить код',
    add: 'Добавить',
    addByCode: 'Ввести код доступа',
    onlyMineFilter: 'Только мои',
    all: 'Все',
    replace: 'Переместить',
    createFolder: 'Создать папку',
    loadFile: 'Загрузить файл',
    inputCode: 'Ввести код',
    download: 'Скачать',
    share: 'Общий доступ',
    rename: 'Переименовать',
    cancelUploading: 'Отменить загрузку',
    cut: 'Вырезать',
    paste: 'Вставить',
    move: 'Переместить',
    select: 'Выбрать',
    selectAll: 'Выбрать все',
    unselect: 'Отменить выделение',
    permissions: 'Права доступа',
    clear: 'Очистить',
    back: 'Назад',
    events: 'События',
    copy: 'Копировать',
  },
  message: {
    deleteEntity: 'Удалить без возможности восстановления?',
    deleteAll: 'Удалить все выделенные?',
    deleteFolder:
      'При удалении папки все содержимое будет перемещено в корень. Вы уверены, что хотите удалить папку?',
    unknownError: 'Возникла непредвиденная ошибка',
    fileTooLarge: 'Загружаемый файл не должен быть более 100 Мб',
    replaceToThisFolder: 'Переместить в данную директорию?',
    replaceToFolder: 'Переместить в ',
    loadFiles: 'Перетащите файлы с компьютера или нажмите кнопку загрузить',
    loaded: 'Файлы успешно загружены',
    canceledByUser: 'Отменена пользователем',
    nothingFound: 'Ничего не найдено',
    filenameCantBeEmpty: 'Отсутвует имя файла',
    addAssignmentGroup: 'Добавить группу аналитик',
  },
  entities: {
    Records: 'Записи',
    RecordFolders: 'Папки',
    Files: 'Файлы',
  },
  recordsArchive: 'Записи и файлы',
  fileFormatForbiden: 'формат файла запрещен',
  emptyArchiveList: 'У вас нет ни одной сохраненной записи с камер',
  name: 'Название',
  finded: 'Найдено совпадений',
  information: 'Информация',
  description: 'Описание',
  emptyDescription: 'Описание отсутствует',
  emptyFolder: 'Папка пуста',
  untitled: 'Без названия',
  downloadError: 'Ошибка скачивания',
  accessCodeError: 'Ошибка в коде доступа',
  rootFolder: 'корневую директорию',
  search: 'Поиск',
  select: 'Выбрать',
  paste: 'Вставить',
  fileSizes: {
    Bytes: 'Байтов',
    KB: 'КБ',
    MB: 'МБ',
    GB: 'ГБ',
    TB: 'ТБ',
    PB: 'ПБ',
    EB: 'ЭБ',
    ZB: 'ЗБ',
    YB: 'ЙБ',
  },
  dialog: {
    deleteTitle: 'Удалить',
    deleteEntity: 'Удалить объект',
    createEntity: 'Создать объект',
    editEntity: 'Редактировать объект',
    accessCode: 'Получить доступ по коду',
    replace: 'Переместить',
    rename: 'Переименовать',
    description: 'Редактировать описание',
    loadFiles: 'Загрузить файлы',
  },
  field: {
    from: 'Начало',
    dateCreated: 'Дата создания',
    endedAt: 'Окончание',
  },
  inputUser: 'Доступ пользователю',
  inputAccessCode: 'Код доступа',
  error: 'Ошибка',
  types: 'Типы',
  calendarButtons: {
    today: 'Сегодня',
    yesterday: 'Вчера',
    week: 'Неделя',
    month: 'Месяц',
  },
  share: {
    Update: 'Изменение',
    Read: 'Просмотр',
    Delete: 'Удаление',
    ReadAssignmentEvent: 'Просмотр событий',
    codeCopied: 'Код скопирован',
    userName: 'Имя пользователя',
    permissions: 'Разрешения',
    permissionAccessCode: 'Доступ по коду',
    owner: 'Владелец',
    dialogHeader: 'Общий доступ',
    findUser: 'Добавить пользователя',
    createCode: 'Создать ссылку',
    deleteCode: 'Удалить ссылку',
    permissionDelete: 'Удалять',
    permissionUpdate: 'Обновлять',
    permissionRead: 'Читать',
    sharedByMe: 'Общий доступ открыт',
    sharedToMe: 'Вам открыт доступ',
    noPermissions: 'Нет доступа',
  },
}
