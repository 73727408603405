







































import Mixin from '../Mixins'

export default Mixin.extend({
  name: 'AccessCode',
  props: {
    value: Array,
    isEmpty: Boolean,
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    typeFilters() {
      return [
        {
          title: this.$t('entities.Records'),
          value: 'Record',
          icon: 'mdi-video',
        },
        {
          title: this.$t('entities.RecordFolders'),
          value: 'RecordFolder',
          icon: 'mdi-folder',
        },
        {
          title: this.$t('entities.Files'),
          value: 'File',
          icon: 'mdi-file',
        },
      ]
    },
  },
  methods: {
    toggleActionsMenu(event: Event & { toggle: true }) {
      this.menuToggle('actionsMenu', event)
    },
    toggleFilter(filterValue: string) {
      if (this.value.includes(filterValue)) {
        this.value.splice(this.value.indexOf(filterValue), 1)
      } else {
        this.value.push(filterValue)
      }
    },
  },
})
