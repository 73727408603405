













































// @ts-ignore
import { createCamerasConnection } from '@netvision/lib-api-gateway'
import Mixins from '@/components/common/Mixins'
const strictRegEx = /[^a-zA-Z0-9а-яА-Я-.,\s]/g
const MAX_CHARS = 280
export default Mixins.extend({
  name: 'Description',
  props: {
    entity: Object as () => ArchiveEntities,
  },
  data() {
    return {
      visible: false,
      MAX_CHARS,
      currentDescription: this.entity.description || '',
    }
  },
  watch: {
    visible(val) {
      if (val) {
        setTimeout(() => {
          const input = document.getElementById(
            `input-${this._uid}`,
          ) as HTMLInputElement
          if (input) {
            input.focus()
          }
        })
      }
    },
    currentDescription(val: string) {
      this.currentDescription = val.replace(strictRegEx, '').slice(0, MAX_CHARS)
    },
  },
  methods: {
    changeDescription(): PromiseConstructor {
      const connection = createCamerasConnection()
      this.currentDescription = this.currentDescription.trim()
      const method =
        this.entity.description === undefined
          ? 'appendEntityAttributes'
          : 'updateEntityAttributes'
      return connection.v2[method](
        {
          type: this.entity.type,
          id: this.entity.id,
          description: this.currentDescription,
        },
        {
          keyValues: true,
        },
      )
        .then(async () => {
          this.visible = false
          this.$eventBus.$emit('updateEntity')
        })
        .catch(this.errorToast)
    },
  },
})
