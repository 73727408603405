
























































































import Vue from 'vue'
import { mapStateTyped } from '@/store'
import AssignmentGroupEditor from './AssignmentGroupEditor.vue'
import EventsPlayer from './EventsPlayer.vue'

export default Vue.extend({
  components: {
    AssignmentGroupEditor,
    EventsPlayer,
  },
  name: 'Assignments',
  props: {
    entity: Object as () => UploadedFile | CameraRecord,
  },
  data() {
    return {
      currentAssignmentGroup: {} as Partial<AssignmentGroup>,
      displayAssignmentEditor: false,
      displayEventsPlayer: false,
      loadingAssignmentId: '' as string | null,
    }
  },
  computed: {
    ...mapStateTyped(['allAssignmentGroups', 'allAssignments']),
    entityAssignmnetGroups(): AssignmentGroup[] {
      return this.allAssignmentGroups.filter(
        ({ entityId }) => entityId === this.entity.id,
      )
    },
    groupAssignmentsCount(): Record<string, number> {
      return this.entityAssignmnetGroups.reduce((acc, { id }) => {
        if (!id) return acc
        acc[id] = this.allAssignments.filter(
          ({ groupId }) => groupId === id,
        ).length
        return acc
      }, {} as Record<string, number>)
    },
  },
  methods: {
    toggleEventsPlayer() {
      this.displayEventsPlayer = !this.displayEventsPlayer
      // @ts-ignore
      this.$refs?.[`${this._uid}eventsPlayer`]?.toggle()
    },
    toggleAssignmentGroupEditor(
      to = false,
      assginmentGrouId = null as null | string,
    ) {
      this.displayAssignmentEditor = !this.displayAssignmentEditor
      // @ts-ignore
      this.$refs?.[`${this._uid}assignmentGroup`]?.toggle()
      if (to === false) {
        this.loadingAssignmentId = assginmentGrouId
        this.$store.dispatch('fetchAssignmentGroupsAndAssignments')
        this.loadingAssignmentId = null
      }
    },
    getGroupIcon({ id }: AssignmentGroup): string {
      if (!id) return 'mdi-numeric-0-box-multiple'
      const count = this.groupAssignmentsCount[id]
      if (count === 0) return 'mdi-spin mdi-loading'
      return count <= 9
        ? `mdi-numeric-${count}-box-multiple`
        : 'mdi-numeric-9-plus-box-multiple'
    },
  },
})
