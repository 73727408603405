












































import ErrorToastMixin from '../ErrorToastMixin'
import { createCamerasConnection } from '@netvision/lib-api-gateway'
import { mapStateTyped } from '@/store'

export default ErrorToastMixin.extend({
  name: 'DeleteDialog',
  props: {
    entity: Object,
    visible: Boolean,
  },
  computed: {
    ...mapStateTyped(['selectedEntities', 'cuttedEntities']),
  },
  data() {
    return {
      option: null,
      show: this.visible,
    }
  },
  watch: {
    visible(val) {
      this.show = val
    },
    show(val) {
      this.$emit('update:visible', val)
    },
  },
  methods: {
    deleteEntity({ id, type }: ArchiveEntities) {
      return createCamerasConnection().v2.deleteEntity({
        type: type,
        id: id,
      })
    },
    deleteAll() {
      Promise.allSettled(
        this.selectedEntities.map((entity: ArchiveEntities) => {
          return this.deleteEntity(entity)
        }),
      ).then(() => {
        this.afterDelete()
        this.$store.commit('setValue', ['selectedEntities', []])
      })
    },
    afterDelete() {
      this.show = false
      const deletedIds = this.entity
        ? [this.entity.id]
        : this.selectedEntities.map(({ id }: any) => id)
      this.$store.commit('setValue', [
        'selectedEntities',
        this.selectedEntities.filter(({ id }: any) => !deletedIds.includes(id)),
      ])
      this.$store.commit('setValue', [
        'cuttedEntities',
        this.cuttedEntities.filter(({ id }: any) => !deletedIds.includes(id)),
      ])
      this.$eventBus.$emit('updateEntity')
    },
  },
})
