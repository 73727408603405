





























































import ErrorToastMixin from '../ErrorToastMixin'
import { name as appName } from '@/../package.json'
import axios from 'axios'
import { createCamerasConnection } from '@netvision/lib-api-gateway'
import { mapState } from 'vuex'
// @ts-ignore
const { url, headers } = createCamerasConnection()
const EXTENTION = /\.[0-9a-z]+$/i
const FORBIDEN_EXTENTIONS = [
  '.js',
  '.exe',
  '.bat',
  '.bash',
  '.run',
  '.cmd',
  '.sh',
  '.py',
  '.apk',
  '.sys',
  '.jar',
  '.x86_64',
]
export default ErrorToastMixin.extend({
  name: 'UploadFile',
  props: {
    parentFolderId: String,
    isLarge: {
      default: false,
    },
  },
  data() {
    return {
      appName,
      visible: false,
      dropArea: {} as HTMLElement,
      uploading: false,
      currentFileName: '',
      totalFilesLength: 0,
      uploadedFilesLength: 0,
      progress: 0,
      fileInputField: {} as HTMLInputElement,
      cancelTokenSource: {} as any,
    }
  },
  computed: {
    ...mapState(['MAX_NAME_LENGTH']),
  },
  watch: {
    visible(val) {
      if (val) {
        setTimeout(() => {
          this.dropArea = document.getElementById(
            `${appName}:drop-area`,
          ) as HTMLElement
          this.fileInputField = document.getElementById(
            `${appName}:file-input`,
          ) as HTMLInputElement
          ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(
            (eventName) => {
              this.dropArea.addEventListener(
                eventName,
                this.preventDefaults,
                false,
              )
            },
          )
          ;['dragenter', 'dragover'].forEach((eventName) => {
            this.dropArea.addEventListener(eventName, this.highlight, false)
          })
          ;['dragleave', 'drop'].forEach((eventName) => {
            this.dropArea.addEventListener(eventName, this.unHighlight, false)
          })
          this.fileInputField.addEventListener(
            'change',
            () => {
              this.uploadSomeFiles(this.fileInputField.files)
            },
            false,
          )
          this.dropArea.addEventListener('drop', this.handleDrop, false)
        })
      }
    },
  },
  methods: {
    preventDefaults(e: Event) {
      e.preventDefault()
      e.stopPropagation()
    },
    highlight() {
      this.dropArea.classList.add('highlight')
    },
    unHighlight() {
      this.dropArea.classList.remove('highlight')
    },
    uploadFile(file: File) {
      const body = new FormData()
      this.currentFileName = file.name.slice(0, this.MAX_NAME_LENGTH)
      body.append('', file, this.currentFileName)
      body.append('name', this.currentFileName)
      body.append('size', String(file.size))
      if (![null, undefined, 'null'].includes(this.parentFolderId)) {
        body.append('parentFolderId', this.parentFolderId)
      }
      this.cancelTokenSource = axios.CancelToken.source()
      return axios
        .post(`${url.href}v1/files`, body, {
          headers,
          cancelToken: this.cancelTokenSource.token,
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader('content-length') ||
                progressEvent.target.getResponseHeader(
                  'x-decompressed-content-length',
                )
            if (totalLength !== null) {
              this.progress = Math.round(
                (progressEvent.loaded * 100) / totalLength,
              )
            }
          },
        })
        .catch((error: any) => {
          if (axios.isCancel(error)) {
            this.totalFilesLength -= 1
            this.uploadedFilesLength -= 1
          } else {
            let message = this.$t('message.unknownError')
            if (error.response.status === 413) {
              message = this.$t('message.fileTooLarge')
            }
            this.errorToast({ message })
          }
        })
    },
    cancelUploading() {
      this.cancelTokenSource.cancel(this.$t('message.canceledByUser'))
    },
    async uploadSomeFiles(files: FileList | null) {
      if (files) {
        this.uploading = true
        this.totalFilesLength = files.length
        this.uploadedFilesLength = 0
        for (const file of files) {
          const extention = file.name.match(EXTENTION)
          if (extention && !FORBIDEN_EXTENTIONS.includes(extention[0])) {
            this.uploadedFilesLength += 1
            await this.uploadFile(file)
          } else {
            this.errorToast({
              message: `${file.name} - ${this.$t('fileFormatForbiden')}`,
            })
          }
        }
        this.uploading = false
        this.visible = false
        this.fileInputField.value = ''
        this.$eventBus.$emit('updateEntity')
      }
    },
    handleDrop(e: Event & { dataTransfer?: DataTransfer }) {
      const dt = e.dataTransfer
      if (dt) {
        const files = dt.files
        this.uploadSomeFiles(files)
      }
    },
  },
  mounted() {},
})
