




























import Vue from 'vue'
export default Vue.extend({
  name: 'User',
  props: {
    user: Object as () => User,
  },
})
