











import Vue from 'vue'
import { mapState } from 'vuex'
export default Vue.extend({
  name: 'Select',
  props: {
    entity: Object as () => ArchiveEntities,
  },
  computed: {
    ...mapState(['selectedEntities']),
  },
  methods: {
    select() {
      this.$store.commit('setValue', [
        'selectedEntities',
        [...this.selectedEntities, this.entity],
      ])
      this.$store.commit('setValue', ['groupMode', true])
    },
  },
})
