var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.onlyIcon === undefined || !_vm.onlyIcon)?_c('Button',{staticClass:"p-button-blurred",attrs:{"icon":"mdi mdi-18px mdi-account-supervisor-outline","label":_vm.$t('button.share'),"data-cy":"button-share"},on:{"click":function($event){_vm.visible = true}}}):_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('button.share')),expression:"$t('button.share')",modifiers:{"top":true}}],staticClass:"mdi mdi-18px mdi-account-supervisor-outline",staticStyle:{"color":"var(--text-color)","cursor":"pointer"},attrs:{"data-cy":"button-icon-share-owner"},on:{"click":function($event){_vm.visible = true}}}),_c('Dialog',{staticClass:"share-dialog",attrs:{"visible":_vm.visible,"modal":true,"closable":true,"dismissableMask":true,"showHeader":false,"id":("shareDialog_" + (_vm.entity.id))},on:{"update:visible":function($event){_vm.visible=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"p-mb-2"},[_c('span',{staticClass:"share-header",domProps:{"textContent":_vm._s(_vm.$t('share.permissionAccessCode'))}})]),_c('div',{staticClass:"p-d-flex p-mb-2"},[(_vm.permissionAccessCode.id)?_c('div',{staticClass:"p-d-flex p-ai-center"},[_c('span',{staticClass:"share-code",attrs:{"data-cy":"share-code"},domProps:{"textContent":_vm._s(_vm.permissionAccessCode.id)}}),_c('Button',{staticClass:"p-button-rounded p-button-text",attrs:{"type":"button","icon":"mdi mdi-content-copy","title":_vm.$t('button.copy'),"data-cy":"button-copy-code"},on:{"click":function($event){return _vm.copyToClipboard()}}}),_c('Button',{staticClass:"p-button-rounded p-button-text",style:({
              position: 'relative',
              padding: '14px',
              left: '-1rem',
            }),attrs:{"type":"button","icon":"mdi mdi-close","title":_vm.$t('button.delete'),"data-cy":"button-delete-code"},on:{"click":function($event){return _vm.deleteAccessCodeScope()}}}),_c('input',{ref:"permissionAccessCode",staticStyle:{"position":"absolute","top":"-40px","opacity":"0"},attrs:{"type":"text"}})],1):_vm._e(),_c('div',{staticStyle:{"flex-grow":"1"}}),(!_vm.permissionAccessCode.id)?_c('Button',{staticClass:"p-button-primary",attrs:{"label":_vm.$t('button.createAccessCode'),"data-cy":"button-create-code"},on:{"click":_vm.createPermissionAccessCode}}):_c('div',{staticClass:"p-d-flex p-jc-end p-flex-column"},[_c('Button',{staticClass:"p-button-outlined",attrs:{"label":_vm.$t('button.permissions'),"data-cy":"button-permissions-toggle-code"},on:{"click":_vm.accessCodePermissionsToggle}}),_c('OverlayPanel',{ref:"accessCodePermissions",attrs:{"appendTo":"body"}},[_c('div',{staticClass:"permission-menu"},[_c('div',{staticClass:"p-d-flex p-jc-between"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t("share.Read"))}}),_c('Checkbox',{attrs:{"data-cy":"checkbox-read-permission-code","name":"permissionAccessCodes","value":'Read' + _vm.entity.type},on:{"click":function($event){return _vm.deleteAccessCodeScope()}},model:{value:(_vm.permissionAccessCode.scope),callback:function ($$v) {_vm.$set(_vm.permissionAccessCode, "scope", $$v)},expression:"permissionAccessCode.scope"}})],1),_vm._l((_vm.accessCodeScopes.filter(
                  function (ref) {
                            var value = ref.value;

                            return value !== ("Read" + (_vm.entity.type));
}
                )),function(ref){
                var value = ref.value;
                var label = ref.label;
return _c('div',{key:value,staticClass:"p-d-flex p-jc-between"},[_c('span',{domProps:{"textContent":_vm._s(label)}}),_c('Checkbox',{attrs:{"name":"permissionAccessCodes","data-cy":"checkbox-update-and-delete-permission-code","value":value},on:{"click":function($event){return _vm.changeAccessCodeScope(value)}},model:{value:(_vm.permissionAccessCode.scope),callback:function ($$v) {_vm.$set(_vm.permissionAccessCode, "scope", $$v)},expression:"permissionAccessCode.scope"}})],1)})],2)])],1)],1),_c('transition-group',{attrs:{"name":"list-complete","tag":"div"}},_vm._l((_vm.permissionAccessCode.accepted),function(accepted){return _c('div',{key:accepted,staticClass:"p-d-flex p-ai-center p-mb-1 list-complete-item"},[_c('User',{attrs:{"user":_vm.usersMap.get(accepted)}}),_c('div',{staticStyle:{"flex-grow":"1"}}),_c('Button',{staticClass:"p-button-rounded p-button-text",attrs:{"icon":"mdi mdi-18px mdi-close"},on:{"click":function($event){return _vm.depriveAccess(accepted)}}})],1)}),0)]},proxy:true}])},[_c('div',{staticClass:"p-mb-3"},[_c('span',{staticClass:"share-header",domProps:{"textContent":_vm._s(_vm.$t('share.dialogHeader'))}})]),_c('div',{staticClass:"user__search"},[_c('span',{staticClass:"p-input-filled p-input-icon-left",staticStyle:{"backdrop-filter":"blur(5px)","width":"100%"}},[_c('i',{staticClass:"mdi mdi-18px mdi-magnify"}),_c('InputText',{class:{
            'p-error': _vm.filteredUsers.length === 0 && _vm.searchUserName !== '',
          },staticStyle:{"width":"100%"},attrs:{"type":"text","placeholder":_vm.$t('search'),"data-cy":"input-search"},model:{value:(_vm.searchUserName),callback:function ($$v) {_vm.searchUserName=$$v},expression:"searchUserName"}})],1),(_vm.searchUserName !== '')?_c('i',{staticClass:"mdi mdi-18px mdi-close close-button",on:{"click":function () {
            _vm.searchUserName = ''
          }}}):_vm._e()]),_c('transition-group',{staticClass:"p-d-flex p-flex-column p-mt-2 users-container",attrs:{"name":"list-complete","tag":"div"}},_vm._l((_vm.filteredUsers),function(user){return _c('div',{key:user.id,staticClass:"p-d-flex p-jc-between p-mb-1 list-complete-item"},[_c('User',{staticStyle:{"width":"50%"},attrs:{"user":user}}),_c('MultiSelectPermissions',{staticStyle:{"width":"50%"},attrs:{"entity":_vm.entity,"user":user,"permissionsByRequesters":_vm.permissionsByRequesters,"permissionOptions":_vm.accessCodeScopes},on:{"updateTickets":_vm.fetchPermissionTickets,"hide":_vm.sortUsers}})],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }