








import Vue from 'vue'
import { mapStateTyped } from '@/store'
export default Vue.extend({
  name: 'AssignmentGroupEditor',
  props: {
    entity: Object as () => CameraRecord | UploadedFile,
    assignmentGroup: Object as () => AssignmentGroup,
    toggleAssignmentGroupEditor: Function,
  },
  data() {
    return {
      unmount: () => {},
    }
  },
  computed: {
    ...mapStateTyped(['spaParent']),
  },
  methods: {
    async mountPlayer() {
      const ref = this.$refs.assignmentEditor
      const player = this.spaParent.areas.find(
        (e: any) => e.name === 'AssignmentEditor',
      )
      if (player) {
        const { id, type, title } = this.entity
        this.unmount = this.spaParent.mountChildren(
          ref,
          player.children.map((e: any) => {
            return {
              ...e,
              props: {
                ...e.props,
                entityId: id,
                entityType: type,
                entityTitle: title,
                assignmentGroupId: this.assignmentGroup?.id,
                onClose: (id: string) => {
                  this.toggleAssignmentGroupEditor(false, id)
                },
              },
            }
          }),
        )
      }
    },
  },
  mounted() {
    this.mountPlayer()
  },
  destroyed() {
    this.unmount()
  },
})
