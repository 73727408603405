





















import Vue from 'vue'
import { mapStateTyped } from '@/store'
export default Vue.extend({
  name: 'EventsPlayer',
  props: {
    entity: Object as () => UploadedFile | CameraRecord,
    toggleEventPlayer: Function,
  },
  data() {
    return {
      unmount: () => {},
    }
  },
  computed: {
    ...mapStateTyped(['spaParent']),
  },
  methods: {
    async mountPlayer() {
      const ref = this.$refs.archivePlayer
      const archivePlayer = this.spaParent.areas.find(
        (e: any) => e.name === 'ArchivePlayer',
      )
      if (archivePlayer !== undefined) {
        this.unmount = this.spaParent.mountChildren(
          ref,
          archivePlayer.children.map((e: any) => {
            return {
              ...e,
              props: {
                ...e.props,
                initialTime: 0,
                entity: this.entity,
                height: 'calc(100vh - 150px)',
              },
            }
          }),
        )
      }
    },
  },
  mounted() {
    this.mountPlayer()
  },
  destroyed() {
    this.unmount()
  },
})
