































































































































// @ts-ignore
import Mixin from './common/Mixins'
import IconPlaceholder from './common/IconPlaceholder.vue'
import PlayerPreview from './common/PlayerPreview.vue'
import Share from './common/menuButtons/Share.vue'
import Delete from './common/menuButtons/Delete.vue'
import Download from './common/menuButtons/Download.vue'
import Rename from './common/menuButtons/Rename.vue'
import Description from './common/menuButtons/Description.vue'
import Cut from './common/menuButtons/Cut.vue'
import Paste from './common/menuButtons/Paste.vue'
import Select from './common/menuButtons/Select.vue'
import Assignments from './common/Assignments.vue'
import { getSharedNotificationSocket } from '@netvision/lib-api-gateway'
import { mapStateTyped } from '@/store'
const formatter = new Intl.DateTimeFormat('ru', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
})

export default Mixin.extend({
  name: 'CardFactory',
  components: {
    Description,
    Share,
    Assignments,
  },
  props: {
    entity: Object as () => ArchiveEntities,
  },
  data() {
    return {
      isCompactView: false,
      removeListener: () => {},
      videoExtensions: ['mp4', 'mov'],
    }
  },
  computed: {
    ...mapStateTyped([
      'cuttedEntities',
      'selectedEntities',
      'groupMode',
      'sharedEntitiesIds',
      'widgetProps',
    ]),
    isCutted(): Boolean {
      return this.cuttedEntities
        .map(({ id }: { id: string }) => id)
        .includes(this.entity.id)
    },
    isSelected: {
      get(): Boolean {
        return this.selectedEntities
          .map(({ id }: ArchiveEntities) => id)
          .includes(this.entity.id)
      },
      set(val: boolean) {
        if (val) {
          this.$store.commit('setValue', [
            'selectedEntities',
            [...this.selectedEntities, this.entity],
          ])
        } else {
          this.$store.commit('setValue', [
            'selectedEntities',
            this.selectedEntities.filter(
              ({ id }: ArchiveEntities) => id !== this.entity.id,
            ),
          ])
        }
      },
    },
    menuButtons(): VueConstr[] {
      const { id, type, owner, status } = this.entity
      const iCanMove =
        (this.canI(`Move${type}`, id) || type === 'RecordFolder') &&
        owner === this.myId
      return [Select, Share, Download, Rename, Paste, Cut, Delete].filter(
        (e: any) =>
          (e === Download &&
            ['Record', 'File'].includes(type) &&
            (status === undefined || status === 'Loaded')) ||
          (e === Rename && this.canI(`Update${type}`, id)) ||
          (e === Share && iCanMove) ||
          (e === Cut &&
            type !== 'RecordFolder' &&
            !this.isCutted &&
            iCanMove) ||
          (e === Select && !this.isSelected && iCanMove) ||
          (e === Delete && this.canI(`Delete${type}`, id)) ||
          (e === Paste &&
            type === 'RecordFolder' &&
            this.cuttedEntities.length > 0 &&
            this.canI('UpdateFolder', id)),
      )
    },
    director(): Card {
      const { id, type, dateCreated } = this.entity
      return {
        Record: {
          isDraggable: this.canI('MoveRecord', id),
          header: {
            component: PlayerPreview,
            props: {},
          },
          assignments:
            !this.widgetProps.assignmentsOff &&
            this.canI('ReadAssignmentEvent', id),
          subtitle: `${this.format(dateCreated)}`,
          informationFields: [
            {
              key: 'dateCreated',
              value: this.format(this.entity.dateCreated),
            },
            {
              key: 'from',
              value:
                ('from' in this.entity && this.format(this.entity.from)) || '',
            },
            {
              key: 'endedAt',
              value:
                'from' in this.entity && 'duration' in this.entity
                  ? this.format(this.entity.from + this.entity.duration)
                  : '',
            },
          ],
        },
        RecordFolder: {
          isDroppable: true,
          header: {
            component: IconPlaceholder,
            props: { action: this.openFolder },
          },
          subtitle: this.format(dateCreated),
        },
        File: {
          isDraggable: this.canI('MoveFile', id),
          assignments:
            !this.widgetProps.assignmentsOff &&
            typeof this.entity.extension !== 'undefined' &&
            this.videoExtensions.includes(this.entity.extension) &&
            this.canI('ReadAssignmentEvent', id),
          header: {
            component:
              this.entity.extension &&
              this.videoExtensions.includes(this.entity.extension)
                ? PlayerPreview
                : IconPlaceholder,
            props:
              this.entity.extension &&
              this.videoExtensions.includes(this.entity.extension)
                ? {}
                : { action: this.download },
          },
          subtitle: `${this.formatBytes((this.entity as UploadedFile).size)}`,
        },
      }[type]
    },
  },
  methods: {
    openFolder(folder: RecordFolder) {
      this.$store.commit('setValue', ['entities', []])
      this.$store.commit('setValue', ['currentFolderName', folder.title])
      this.$store.commit('setValue', [
        'filters',
        { ...this.filters, parentFolderId: [folder.id] },
      ])
    },
    format(date: string | number | null) {
      if (typeof date === 'number') {
        return formatter.format(new Date(date * 1000))
      } else {
        const parsedDate = Date.parse(date || '')
        return isNaN(parsedDate) ? '' : formatter.format(parsedDate)
      }
    },
    highlight(title: string, filter: string) {
      return this.$options?.filters?.highlight(title, filter || '')
    },
    toggleActionsMenu(event: Event & { toggle: true }) {
      this.menuToggle('actionsMenu', event)
    },
  },
  mounted() {
    const { id, status, type } = this.entity
    if (type === 'Record' && status !== undefined && status !== 'Loaded') {
      this.removeListener = getSharedNotificationSocket().addListener(
        'Record',
        ({ id: fetchedId, status: fetchedStatus }: CameraRecord) => {
          if (id === fetchedId) {
            this.entity.status = fetchedStatus
          }
        },
      )
    }
  },
  beforeDestroy() {
    this.removeListener()
  },
})
