











import Mixin from '../Mixins'
export default Mixin.extend({
  name: 'Download',
  props: {
    entity: Object,
  },
})
