























import Vue from 'vue'
export default Vue.extend({
  name: 'IconPlaceholder',
  props: {
    entity: Object as () => RecordFolder | UploadedFile,
    action: Function,
  },
})
