
























































































































































































import Mixin from '../Mixins'
import { createCamerasConnection } from '@netvision/lib-api-gateway'
import User from '../User.vue'
import MultiSelectPermissions from '../MultiSelectPermissions.vue'
import { mapState } from 'vuex'

const strictRegEx = /[^a-zA-Z0-9а-яА-Я-.,\s]/g
export default Mixin.extend({
  name: 'Share',
  components: {
    User,
    MultiSelectPermissions,
  },
  props: {
    entity: Object,
    onlyIcon: Boolean,
  },
  data() {
    return {
      visible: false,
      searchUserName: '',
      users: [] as User[],
      permissionTickets: [] as PermissionTicket[],
      usersMap: new Map() as Map<string, object>,
      permissionsByRequesters: {} as Record<string, PermissionTicket[]>,
      permissionAccessCode: { accepted: [], scope: [] } as Partial<
        PermissionAccessCode
      >,
      isShared: false,
    }
  },
  watch: {
    async visible(val) {
      if (val) {
        await this.fetchUsersInfo()
        await this.fetchPermissionAccessCode()
        await this.fetchPermissionTickets()
        this.sortUsers()
      }
    },
    searchUserName(val: string) {
      const clearedVal = val.replace(strictRegEx, '')
      if (this.searchUserName.length !== clearedVal.length) {
        this.searchUserName = clearedVal
      }
    },
  },
  computed: {
    ...mapState(['sharedEntitiesIds']),
    accessCodeScopes() {
      const accessCodeScopes = ['Read', 'Update', 'Delete'].map((scope) => {
        return {
          value: `${scope}${this.entity.type}`,
          label: this.$t(`share.${scope}`),
        }
      })
      if ('streamUrl' in this.entity || this.entity.type === 'Record') {
        accessCodeScopes.push({
          value: 'ReadAssignmentEvent',
          label: this.$t('share.ReadAssignmentEvent'),
        })
      }
      return accessCodeScopes
    },
    filteredUsers(): User[] {
      const search = new RegExp(this.searchUserName.trim(), 'i')
      return this.users.filter(({ fullName, email }) => {
        return search.test(fullName || '') || search.test(email || '')
      })
    },
  },
  methods: {
    copyToClipboard() {
      let inputField = this.$refs.permissionAccessCode as HTMLInputElement
      inputField = Array.isArray(inputField) ? inputField[0] : inputField
      inputField.value = this.permissionAccessCode.id || ''
      inputField.select()
      document.execCommand('copy')
      this.$toast.add({
        severity: 'info',
        summary: this.$t('share.codeCopied'),
        detail: inputField.value,
        life: 3000,
      })
    },
    sortUsers() {
      this.users.sort(({ id: idA }, { id: idB }) => {
        const a = this.permissionsByRequesters[idA] !== undefined ? 0 : 1
        const b = this.permissionsByRequesters[idB] !== undefined ? 0 : 1
        return a - b
      })
    },
    depriveAccess(id: string) {
      this.permissionAccessCode.accepted = this.permissionAccessCode?.accepted?.filter(
        (e) => e !== id,
      )
      createCamerasConnection()
        .v2.updateEntityAttributes(
          {
            id: this.permissionAccessCode.id,
            type: 'PermissionAccessCode',
            accepted: this.permissionAccessCode.accepted,
          },
          {
            keyValues: true,
          },
        )
        .then(async () => {
          this.fetchPermissionAccessCode()
        })
        .catch(this.errorToast)
    },
    deleteAccessCodeScope(): Promise<void> {
      return createCamerasConnection()
        .v2.deleteEntity({
          type: 'PermissionAccessCode',
          id: this.permissionAccessCode.id,
        })
        .then(async () => {
          await this.fetchPermissionAccessCode()
        })
        .catch(this.errorToast)
    },
    changeAccessCodeScope(scope: string) {
      const scopes = this.permissionAccessCode?.scope?.includes(scope)
        ? this.permissionAccessCode.scope.filter((e) => e !== scope)
        : [...(this.permissionAccessCode?.scope || []), scope]
      createCamerasConnection()
        .v2.updateEntityAttributes(
          {
            type: 'PermissionAccessCode',
            id: this.permissionAccessCode.id,
            scope: scopes,
          },
          {
            keyValues: true,
          },
        )
        .then(async () => {
          this.permissionAccessCode.scope = scopes
          this.fetchPermissionAccessCode()
        })
        .catch(this.errorToast)
    },
    createPermissionAccessCode() {
      const connection = createCamerasConnection()
      return connection.v2
        .createEntity(
          {
            type: 'PermissionAccessCode',
            id: '',
            scope: [`Read${this.entity.type}`],
            entityId: this.entity.id,
          },
          {
            keyValues: true,
          },
        )
        .then(() => {
          this.fetchPermissionAccessCode()
        })
        .catch(this.errorToast)
    },
    fetchUsersInfo() {
      const connection = createCamerasConnection()
      return connection.v2
        .listEntities({
          type: 'User',
          keyValues: true,
          limit: 1000,
        })
        .then(async ({ results }: { results: User[] }) => {
          const users = new Map()
          this.users = results.filter(({ id }) => id !== this.myId)
          results.forEach((user: User) => {
            users.set(user.id, user)
          })
          this.usersMap = users
        })
        .catch(this.errorToast)
    },
    fetchPermissionAccessCode() {
      return createCamerasConnection()
        .v2.listEntities({
          q: `entityId==${this.entity.id}`,
          type: 'PermissionAccessCode',
          keyValues: true,
        })
        .then(async (response: any) => {
          this.permissionAccessCode = response.results[0] || { accepted: [] }
          this.checkIsItShared()
        })
    },
    accessCodePermissionsToggle(event: Event) {
      this.menuToggle('accessCodePermissions', event)
    },
    fetchPermissionTickets() {
      return createCamerasConnection()
        .v2.listEntities({
          q: `entityId==${this.entity.id}`,
          type: 'PermissionTicket',
          keyValues: true,
        })
        .then(({ results }: any) => {
          if (Array.isArray(results)) {
            const permissons = {} as Record<string, PermissionTicket[]>
            results.forEach(
              ({ requester, ...restTicket }: PermissionTicket) => {
                if (requester in permissons) {
                  const userTickets = permissons[requester]
                  userTickets.push({ requester, ...restTicket })
                  permissons[requester] = userTickets
                } else {
                  permissons[requester] = [{ requester, ...restTicket }]
                }
              },
            )
            this.permissionsByRequesters = permissons
          }
          this.checkIsItShared()
        })
    },
    checkIsItShared() {
      const isShared =
        !!this.permissionAccessCode.id ||
        Object.keys(this.permissionsByRequesters).length > 0

      if (isShared && !this.isShared) {
        this.$store.commit('setValue', [
          'sharedEntitiesIds',
          Array.from(new Set([...this.sharedEntitiesIds, this.entity.id])),
        ])
      } else if (!isShared && this.isShared) {
        this.$store.commit('setValue', [
          'sharedEntitiesIds',
          this.sharedEntitiesIds.filter((e: string) => e !== this.entity.id),
        ])
      }
      this.isShared = isShared
    },
  },
})
