

















































// @ts-ignore
import { createCamerasConnection } from '@netvision/lib-api-gateway'
import Mixins from '@/components/common/Mixins'
const strictRegEx = /[^a-zA-Z0-9а-яА-Я-.,\s]/g
const MAX_CHARS = 25
export default Mixins.extend({
  name: 'CreateFolder',
  props: {
    isLarge: {
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      folderTitle: '',
      MAX_CHARS,
    }
  },
  watch: {
    visible(val) {
      if (val) {
        setTimeout(() => {
          const input = document.getElementById(
            `input-${this._uid}`,
          ) as HTMLInputElement
          if (input) {
            input.focus()
          }
        })
      }
    },
    folderTitle(val: string) {
      this.folderTitle = val.replace(strictRegEx, '').slice(0, MAX_CHARS)
    },
  },
  methods: {
    createFolder(): Promise<void> {
      return createCamerasConnection()
        .v2.createEntity(
          {
            type: 'RecordFolder',
            id: '',
            title: this.folderTitle,
            parentFolderId: null,
          },
          {
            keyValues: true,
          },
        )
        .then(async () => {
          this.folderTitle = ''
          this.visible = false
          this.$eventBus.$emit('updateEntity')
        })
        .catch(this.errorToast)
    },
  },
})
