(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("primevue/inputtext"), require("primevue/calendar"), require("primevue/paginator"), require("primevue/textarea"), require("primevue/inputswitch"), require("primevue/splitbutton"), require("primevue/scrollpanel"), require("primevue/togglebutton"), require("primevue/dropdown"), require("primevue/tooltip"), require("single-spa-vue"), require("@netvision/lib-history"), require("primevue/toastservice"), require("primevue/autocomplete"), require("vue-i18n"), require("primevue/progressspinner"), require("vue"), require("primevue/selectbutton"), require("@netvision/lib-api-gateway"), require("primevue/checkbox"), require("primevue/multiselect"), require("primevue/toast"), require("primevue/progressbar"), require("primevue/dialog"), require("primevue/overlaypanel"), require("primevue/button"), require("primevue/menu"));
	else if(typeof define === 'function' && define.amd)
		define(["primevue/inputtext", "primevue/calendar", "primevue/paginator", "primevue/textarea", "primevue/inputswitch", "primevue/splitbutton", "primevue/scrollpanel", "primevue/togglebutton", "primevue/dropdown", "primevue/tooltip", "single-spa-vue", "@netvision/lib-history", "primevue/toastservice", "primevue/autocomplete", "vue-i18n", "primevue/progressspinner", "vue", "primevue/selectbutton", "@netvision/lib-api-gateway", "primevue/checkbox", "primevue/multiselect", "primevue/toast", "primevue/progressbar", "primevue/dialog", "primevue/overlaypanel", "primevue/button", "primevue/menu"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("primevue/inputtext"), require("primevue/calendar"), require("primevue/paginator"), require("primevue/textarea"), require("primevue/inputswitch"), require("primevue/splitbutton"), require("primevue/scrollpanel"), require("primevue/togglebutton"), require("primevue/dropdown"), require("primevue/tooltip"), require("single-spa-vue"), require("@netvision/lib-history"), require("primevue/toastservice"), require("primevue/autocomplete"), require("vue-i18n"), require("primevue/progressspinner"), require("vue"), require("primevue/selectbutton"), require("@netvision/lib-api-gateway"), require("primevue/checkbox"), require("primevue/multiselect"), require("primevue/toast"), require("primevue/progressbar"), require("primevue/dialog"), require("primevue/overlaypanel"), require("primevue/button"), require("primevue/menu")) : factory(root["primevue/inputtext"], root["primevue/calendar"], root["primevue/paginator"], root["primevue/textarea"], root["primevue/inputswitch"], root["primevue/splitbutton"], root["primevue/scrollpanel"], root["primevue/togglebutton"], root["primevue/dropdown"], root["primevue/tooltip"], root["single-spa-vue"], root["@netvision/lib-history"], root["primevue/toastservice"], root["primevue/autocomplete"], root["vue-i18n"], root["primevue/progressspinner"], root["vue"], root["primevue/selectbutton"], root["@netvision/lib-api-gateway"], root["primevue/checkbox"], root["primevue/multiselect"], root["primevue/toast"], root["primevue/progressbar"], root["primevue/dialog"], root["primevue/overlaypanel"], root["primevue/button"], root["primevue/menu"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__025a__, __WEBPACK_EXTERNAL_MODULE__0482__, __WEBPACK_EXTERNAL_MODULE__0d70__, __WEBPACK_EXTERNAL_MODULE__12bc__, __WEBPACK_EXTERNAL_MODULE__199d__, __WEBPACK_EXTERNAL_MODULE__3261__, __WEBPACK_EXTERNAL_MODULE__342a__, __WEBPACK_EXTERNAL_MODULE__35c9__, __WEBPACK_EXTERNAL_MODULE__3746__, __WEBPACK_EXTERNAL_MODULE__3b23__, __WEBPACK_EXTERNAL_MODULE__4171__, __WEBPACK_EXTERNAL_MODULE__4ebd__, __WEBPACK_EXTERNAL_MODULE__68f9__, __WEBPACK_EXTERNAL_MODULE__6920__, __WEBPACK_EXTERNAL_MODULE__85b3__, __WEBPACK_EXTERNAL_MODULE__8aba__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_a28b__, __WEBPACK_EXTERNAL_MODULE_bb6b__, __WEBPACK_EXTERNAL_MODULE_bc44__, __WEBPACK_EXTERNAL_MODULE_be9f__, __WEBPACK_EXTERNAL_MODULE_d26e__, __WEBPACK_EXTERNAL_MODULE_d833__, __WEBPACK_EXTERNAL_MODULE_dec9__, __WEBPACK_EXTERNAL_MODULE_dffb__, __WEBPACK_EXTERNAL_MODULE_f095__, __WEBPACK_EXTERNAL_MODULE_f4f3__) {
return 