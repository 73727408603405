

















import ErrorToastMixin from '../ErrorToastMixin'
import DeleteDialog from './DeleteDialog.vue'
import { mapStateTyped } from '@/store'

export default ErrorToastMixin.extend({
  name: 'Delete',
  components: {
    DeleteDialog,
  },
  props: {
    entity: Object,
  },
  computed: {
    ...mapStateTyped(['selectedEntities', 'cuttedEntities']),
  },
  data() {
    return {
      visible: false,
      option: null,
    }
  },
})
