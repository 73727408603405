





import Vue from 'vue'
import { mapState } from 'vuex'
export default Vue.extend({
  name: 'PlayerPreview',
  props: {
    entity: Object as () => CameraRecord,
  },
  data() {
    return {
      unmount: () => {},
    }
  },
  computed: {
    ...mapState(['spaParent']),
  },
  mounted() {
    let ref = this.$refs.player
    ref = Array.isArray(ref) ? ref[0] : ref
    const player = this.spaParent.areas.find((e: any) => e.name === 'player')
    if (player) {
      this.unmount = this.spaParent.mountChildren(
        ref,
        player.children.map((e: any) => {
          return {
            ...e,
            props: {
              record: this.entity,
              ratio: 'fit',
            },
          }
        }),
      )
    }
  },
  beforeDestroy() {
    this.unmount()
  },
})
