
















import Vue from 'vue'
import CreateFolder from './common/menuButtons/CreateFolder.vue'
import UploadFile from './common/menuButtons/UploadFile.vue'
import AccessCode from './common/menuButtons/AccessCode.vue'

export default Vue.extend({
  name: 'EmptyArchive',
  components: {
    UploadFile,
    CreateFolder,
    AccessCode,
  },
})
