import Vue from 'vue'

export default Vue.extend({
  name: 'ErrorToastMixin',
  methods: {
    errorToast(error: any) {
      console.log(error)
      this.$toast.add({
        severity: 'error',
        summary: this.$t('error'),
        detail: error.message,
        life: 5000,
      })
    },
  },
})
