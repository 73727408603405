import { render, staticRenderFns } from "./EmptyArchive.vue?vue&type=template&id=6ea539e5&scoped=true&"
import script from "./EmptyArchive.vue?vue&type=script&lang=ts&"
export * from "./EmptyArchive.vue?vue&type=script&lang=ts&"
import style0 from "./EmptyArchive.vue?vue&type=style&index=0&id=6ea539e5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ea539e5",
  null
  
)

export default component.exports