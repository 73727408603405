














import Vue from 'vue'
import { mapState } from 'vuex'
export default Vue.extend({
  name: 'Cut',
  props: {
    entity: Object as () => ArchiveEntities,
  },
  computed: {
    ...mapState(['selectedEntities']),
  },
  methods: {
    cut() {
      this.$store.commit('setValue', ['cuttedEntities', [this.entity]])
    },
    cutAll() {
      this.$store.commit('setValue', ['cuttedEntities', this.selectedEntities])
      this.$store.commit('setValue', ['selectedEntities', []])
      this.$store.commit('setValue', ['groupMode', false])
    },
  },
})
