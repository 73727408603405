var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{style:({ width: 'calc(410rem / var(--bfs))' }),attrs:{"header":_vm.$t('dialog.deleteTitle'),"visible":_vm.show,"position":"center","closable":false,"modal":true,"dismissableMask":true},on:{"update:visible":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Button',{attrs:{"data-cy":"button-delete-dialog-delete","label":_vm.$t('button.delete')},on:{"click":function($event){_vm.entity
          ? _vm.deleteEntity(_vm.entity)
              .then(_vm.afterDelete)
              .catch(_vm.errorToast)
          : _vm.deleteAll()}}}),_c('Button',{staticClass:"p-button-secondary p-button-outlined",attrs:{"data-cy":"button-cancel-dialog-delete","label":_vm.$t('button.cancel')},on:{"click":function($event){_vm.show = false}}})]},proxy:true}])},[_c('div',{staticClass:"p-mx-3",staticStyle:{"min-height":"1.3rem"}},[(_vm.entity)?_c('span',{domProps:{"textContent":_vm._s(
        _vm.entity.type === 'RecordFolder'
          ? _vm.$t('message.deleteFolder')
          : _vm.$t('message.deleteEntity')
      )}}):_c('span',{domProps:{"textContent":_vm._s(_vm.$t('message.deleteAll'))}})])])}
var staticRenderFns = []

export { render, staticRenderFns }