







import Vue from 'vue'
import Files from './components/ArchiveList.vue'
export default Vue.extend({
  name: 'App',
  components: {
    Files,
  },
  beforeMount() {
    this.$store.commit('reset')
    this.$store.commit('setValue', ['spaParent', this.$parent])
    this.$store.commit('setValue', ['widgetProps', this.$parent.props])
  },
})
