













































































































































































































































import {
  createCamerasConnection,
  getPermissionsByIdsMap,
  getPermittedScopes,
  getUserInfo,
  listEntities,
} from '@netvision/lib-api-gateway'
// @ts-ignore
import { Draggable } from '@shopify/draggable'
import EmptyArchive from './EmptyArchive.vue'
import EmptySearch from './EmptySearch.vue'
import CardFactory from './CardFactory.vue'
import Mixins from './common/Mixins'
import CreateFolder from './common/menuButtons/CreateFolder.vue'
import AccessCode from './common/menuButtons/AccessCode.vue'
import TypeFilters from './common/menuButtons/TypeFilters.vue'
import UploadFile from './common/menuButtons/UploadFile.vue'
import Delete from './common/menuButtons/Delete.vue'
import Paste from './common/menuButtons/Paste.vue'
import Cut from './common/menuButtons/Cut.vue'
import PasteToFolder from './common/PasteToFolder.vue'
import CalendarFilter from './common/CalendarFilter.vue'
import { mapState } from 'vuex'
import { history, SearchParams } from '@netvision/lib-history'

const strictRegEx = /[^a-zA-Z0-9а-яА-Я-.,\s]/g
export default Mixins.extend({
  name: 'ArchiveList',
  components: {
    EmptyArchive,
    EmptySearch,
    CardFactory,
    CreateFolder,
    UploadFile,
    AccessCode,
    TypeFilters,
    PasteToFolder,
    Paste,
    Delete,
    Cut,
    CalendarFilter,
  },
  data() {
    return {
      loading: true,
      onlyMine: false,
      dates: [new Date(), null],
      searchTitle: '',
      parentFolderId: null,
      overFolder: { id: null, name: null } as any,
      shareEntity: {} as any,
      sharedEntities: [] as string[],
      currentRoutePath: history.location.pathname,
      showSkeleton: true,
      draggable: {
        destroy() {},
      } as any,
      initLimit: 6,
      limit: 6,
      offset: 0,
      count: 0,
      inputTimer: {} as any,
      archivePlayerDialog: false,
      accessCode: '',
      entityTypes: [] as string[],
      initEntityTypes: ['Record', 'RecordFolder', 'File'],
      unlisten: () => {},
    }
  },
  computed: {
    ...mapState([
      'cuttedEntities',
      'targetFolder',
      'selectedEntities',
      'groupMode',
    ]),
    isRootFolder(): Boolean {
      return [null, 'null'].includes(this.filters.parentFolderId[0])
    },
    fileInputField(): HTMLInputElement {
      return document.getElementById(
        // @ts-ignore
        `file-input${this.$options._scopeId}`,
      ) as HTMLInputElement
    },
    nothingFound(): Boolean {
      const filtersEmpty =
        Object.entries(this.filters).every(([key, val]) =>
          Array.isArray(val)
            ? val.every((e: any) => !e || e === 'null') ||
              key === 'parentFolderId'
            : !val,
        ) && this.entityTypes.length === 0
      return this.count === 0 && !this.loading && !filtersEmpty
    },
  },
  watch: {
    searchTitle(val: string) {
      const clearedVal = val.replace(strictRegEx, '')
      if (this.searchTitle.length === clearedVal.length) {
        this.requestDelay(val.length > 1 || val.length === 0, () => {
          this.filters.title = val
        })
      } else {
        this.searchTitle = clearedVal
      }
    },
    offset(val: number) {
      history.push({
        search: SearchParams.stringify({
          ...SearchParams.parse(history.location.search),
          offset: val,
        }),
      })
    },
    accessCode(val: string) {
      const clearedVal = val.replace(strictRegEx, '')
      if (this.accessCode.length !== clearedVal.length) {
        this.accessCode = clearedVal
      }
    },
    onlyMine(val: Boolean) {
      if (val) {
        this.filters.owner = this.myId
      } else {
        this.filters.owner = ''
      }
    },
    filters: {
      handler(val) {
        const queryList: string[] = []
        Object.entries(val).forEach(([key, value]: any[]) => {
          value = Array.isArray(value) ? value.join(',') : value
          if (value !== null && value.length !== 0) {
            const filter =
              key === 'title' ? `${key}~=${value}` : `${key}==${value}`
            queryList.push(filter)
          }
        })
        const query = {
          ...SearchParams.parse(history.location.search),
          q: queryList.join(';'),
        } as any
        if (queryList.length === 0) {
          delete query.q
        }
        history.push({
          search: SearchParams.stringify({
            ...query,
          }),
        })
      },
      deep: true,
    },
  },
  created() {
    this.$eventBus.$on('updateEntity', this.initData)
  },
  methods: {
    closeFolder() {
      this.$store.commit('setValue', ['entities', []])
      this.$store.commit('setValue', ['currentFolderName', ''])
      history.push({
        search: SearchParams.stringify({
          ...SearchParams.parse(history.location.search),
          offset: 0,
          q: 'parentFolderId==null',
        }),
      })
    },
    limitWatcher(val: number) {
      const newOffset = val * Math.trunc(this.offset / val)
      history.push({
        search: SearchParams.stringify({
          ...SearchParams.parse(history.location.search),
          limit: `${val}`,
          offset: `${newOffset}`,
        }),
      })
    },
    entityTypesWatcher(val: string[]) {
      if (val.length > 0) {
        history.push({
          search: SearchParams.stringify({
            ...SearchParams.parse(history.location.search),
            type: val.join(','),
          }),
        })
      } else {
        const query = SearchParams.parse(history.location.search)
        delete query.type
        history.push({
          search: SearchParams.stringify(query),
        })
      }
    },
    fetchEntities() {
      let { q } = SearchParams.parse(history.location.search) as {
        q: string | undefined
      }
      if (typeof q === 'string') {
        const searchRequests = q.match(/(?=title~=)[^;]+(;|$)/)
        if (searchRequests !== null && searchRequests.length > 0) {
          const searchRequest = searchRequests[0]
            .replace('title~=', '')
            .replace(';', '')
          q = q.replace(searchRequest, this.insensitify(searchRequest))
        }
      }
      return listEntities(createCamerasConnection(), {
        offset:
          this.isRootFolder || this.offset === 0
            ? this.offset
            : this.offset - 1,
        limit: this.isRootFolder ? this.limit : this.limit - 1,
        type: this.entityTypes.join(',') || this.initEntityTypes.join(','),
        q,
        attrs: 'dateCreated,*',
        orderBy: '!type,dateCreated',
        count: true,
      }).then(async ({ results: entities, count }) => {
        this.$store.commit('setValue', ['entities', [...entities]])
        const countNum = Number(count || 0)
        this.count =
          this.isRootFolder || countNum === 0 ? countNum : countNum + 1
        this.$store.commit('setValue', [
          'permissionScopes',
          new Map([
            ...this.permissionScopes,
            ...(await getPermissionsByIdsMap(
              entities.map((e: any) =>
                'resourceId' in e ? e.resourceId : (e.id as string),
              ),
            )),
          ]),
        ])
        this.showSkeleton = false
        this.fetchSharedTicketsCodes()
      })
    },
    fetchSharedTicketsCodes() {
      const connection = createCamerasConnection()
      connection.v2
        .listEntities({
          type: 'PermissionTicket,PermissionAccessCode',
          attrs: 'entityId',
          q: `owner==${this.myId}`,
          keyValues: true,
          limit: 1000,
        })
        .then((response: any) => {
          this.$store.commit('setValue', [
            'sharedEntitiesIds',
            response.results.map((e: any) => e.entityId),
          ])
        })
    },
    parseCurrentQuery() {
      const query = SearchParams.parse(history.location.search)
      const parsedFilters = {} as any
      if (query.q !== undefined) {
        const options = (query.q as string).split(';')
        options.forEach((e: string) => {
          let [key, value] = e.split('==')
          if (value === undefined) [key, value] = key.split('~=')
          // Имя не массив
          switch (key) {
            case 'title':
              this.searchTitle = parsedFilters.title = value
              break
            case 'dateCreated':
              this.dates = value.split('..').map((e) => new Date(Date.parse(e)))
              parsedFilters.dateCreated = value
              break
            default: {
              // Может быть массив значений через запятую
              const values = value.split(',')
              // Парсим числа как числа, а не как строки
              parsedFilters[key] = values.some((el: string) =>
                isNaN(Number(el)),
              )
                ? values
                : values.map((el: string) => Number(el))
              break
            }
          }
          this.onlyMine =
            typeof query?.q === 'string'
              ? query.q.includes(`owner==${this.myId}`)
              : false
        })
      }
      if (!parsedFilters.title) {
        this.searchTitle = ''
      }
      if (!parsedFilters.dateCreated) {
        this.dates = [new Date(), null]
      }
      if (
        Array.isArray(parsedFilters.parentFolderId) &&
        parsedFilters.parentFolderId[0] !== 'null' &&
        this.currentFolderName === ''
      ) {
        setTimeout(async () => {
          try {
            const {
              entity: { title },
            } = await createCamerasConnection().v2.getEntity({
              id: parsedFilters.parentFolderId,
              type: 'RecordFolder',
              keyValues: true,
            })
            this.$store.commit('setValue', ['currentFolderName', title || null])
          } catch (error) {
            console.error(error)
          }
        })
      }
      this.limit =
        query.limit !== undefined &&
        !isNaN(Number(query.limit)) &&
        Number(query.limit) > 0
          ? Math.abs(Number(query.limit))
          : this.initLimit
      this.offset =
        query.offset !== undefined && !isNaN(Number(query.offset))
          ? Math.abs(Number(query.offset))
          : 0
      this.entityTypes =
        query.type !== undefined ? (query.type as string).split(',') : []
      this.$store.commit('setValue', [
        'filters',
        parsedFilters.parentFolderId === undefined
          ? { ...this.filters, ...parsedFilters, parentFolderId: ['null'] }
          : { ...this.filters, ...parsedFilters },
      ])
    },
    async initData() {
      this.loading = true
      await this.parseCurrentQuery()
      await this.fetchEntities()
      this.loading = false
      this.initDrag()
    },
    initDrag() {
      this.draggable.destroy()
      this.draggable = new Draggable(document.querySelectorAll('.card'), {
        draggable: '.card_draggable',
        handle: '.card__handler',
      })
      this.draggable.on('drag:start', () => {
        this.overFolder = { id: null }
      })
      this.draggable.on('drag:over', (event: any) => {
        if (event.data.over.classList.contains('card_droppable')) {
          this.overFolder = { ...event.data.over.dataset }
        } else {
          this.overFolder = { id: null }
        }
      })
      this.draggable.on('drag:out', () => {
        this.overFolder = { id: null }
      })
      this.draggable.on('drag:stop', (event: any) => {
        if (this.overFolder.id !== null) {
          this.$store.commit('setValue', [
            'targetFolder',
            {
              id: this.overFolder.id,
              title: this.overFolder.title,
            },
          ])
          this.$store.commit('setValue', [
            'cuttedEntities',
            [
              {
                id: event.data.originalSource.dataset.id,
                type: event.data.originalSource.dataset.type,
              },
            ],
          ])
        }
      })
    },
    selectAll() {
      this.$store.commit('setValue', [
        'selectedEntities',
        Array.from(
          new Set([
            ...this.selectedEntities,
            ...this.entities.filter(({ owner }: any) => this.myId === owner),
          ]),
        ),
      ])
    },
    unselect() {
      this.$store.commit('setValue', ['selectedEntities', []])
    },
  },
  async mounted() {
    const { userId } = await getUserInfo()
    this.$store.commit('setValue', ['myId', userId])
    if (SearchParams.parse(history.location.search).limit === undefined) {
      const containerWidth: number = (this.$refs.container as HTMLElement)
        .clientWidth
      switch (true) {
        case containerWidth - 1480 < 0:
          this.initLimit = this.limit = 6
          break
        case containerWidth - 1540 < 0:
          this.initLimit = this.limit = 8
          break
        default:
          this.initLimit = this.limit = 10
      }
    }
    this.permissionScopes.set(
      null,
      await getPermittedScopes(
        this.initEntityTypes.map((e: string) => `Read${e}`),
      ),
    )
    this.$watch('entityTypes', this.entityTypesWatcher)
    const createPermissions: string[] = await getPermittedScopes([
      'CreateFile',
      'CreateRecordFolder',
    ])
    this.$store.commit('setValue', [
      'permissionScopes',
      this.permissionScopes.set(null, createPermissions),
    ])
    this.$store.dispatch('fetchAssignmentGroupsAndAssignments')
    this.initData()
    this.$watch('limit', this.limitWatcher)
    let search = JSON.stringify(SearchParams.parse(history.location.search))
    this.unlisten = history.listen(async (location) => {
      const locaSearch = JSON.stringify(SearchParams.parse(location.search))
      if (
        location.pathname === this.currentRoutePath &&
        locaSearch !== search
      ) {
        search = locaSearch
        this.initData()
      }
    })
  },
  beforeDestroy() {
    this.unlisten()
    this.draggable.destroy()
  },
})
