import './set-public-path'
import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'
import VueI18n from 'vue-i18n'
import { RUSSIAN_TRANSLATIONS } from './translations/ru'
import Vuex from 'vuex'
import Store from './store'

import ProgressSpinner from 'primevue/progressspinner'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import Textarea from 'primevue/textarea'
import InputText from 'primevue/inputtext'
import SplitButton from 'primevue/splitbutton'
import Paginator from 'primevue/paginator'
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'
import Dropdown from 'primevue/dropdown'
import Menu from 'primevue/menu'
import Tooltip from 'primevue/tooltip'
import AutoComplete from 'primevue/autocomplete'
import SelectButton from 'primevue/selectbutton'
import ToggleButton from 'primevue/togglebutton'
import InputSwitch from 'primevue/inputswitch'
import Checkbox from 'primevue/checkbox'
import ProgressBar from 'primevue/progressbar'
import OverlayPanel from 'primevue/overlaypanel'
import MultiSelect from 'primevue/multiselect'
import Calendar from 'primevue/calendar'
import ScrollPanel from 'primevue/scrollpanel'
import App from './App.vue'

Vue.prototype.$eventBus = new Vue()
Vue.use(VueI18n)
Vue.use(Vuex)
Vue.use(ToastService)
Vue.directive('tooltip', Tooltip)
Vue.component('ProgressSpinner', ProgressSpinner)
Vue.component('Dialog', Dialog)
Vue.component('Button', Button)
Vue.component('Textarea', Textarea)
Vue.component('InputText', InputText)
Vue.component('SplitButton', SplitButton)
Vue.component('Paginator', Paginator)
Vue.component('Toast', Toast)
Vue.component('Dropdown', Dropdown)
Vue.component('Menu', Menu)
Vue.component('AutoComplete', AutoComplete)
Vue.component('SelectButton', SelectButton)
Vue.component('ToggleButton', ToggleButton)
Vue.component('InputSwitch', InputSwitch)
Vue.component('Checkbox', Checkbox)
Vue.component('ProgressBar', ProgressBar)
Vue.component('OverlayPanel', OverlayPanel)
Vue.component('MultiSelect', MultiSelect)
Vue.component('Calendar', Calendar)
Vue.component('ScrollPanel', ScrollPanel)

Vue.filter('highlight', (word: string, query: string) => {
  if (!word) return
  const check = new RegExp(query, 'ig')
  return word.toString().replace(check, (matchedText) => {
    return `<strong style='color: var(--text-color)'>${matchedText}</strong>`
  })
})
Vue.config.productionTip = false

const locale = 'ru'
const TRANSLATIONS = {
  ru: RUSSIAN_TRANSLATIONS,
}
const i18n = new VueI18n({
  locale,
  messages: TRANSLATIONS,
})
const store = new Vuex.Store(Store)

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    i18n,
    store,
    render: (h: any) => h(App),
  },
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = (props: any) => {
  if (props.domElement && props.vueAppend) {
    const el = document.createElement('div')
    props.domElement.appendChild(el)
    props.domElement = el
  }
  return vueLifecycles.mount(props)
}
export const unmount = vueLifecycles.unmount
export const update = vueLifecycles.update
