



















import Vue from 'vue'
export default Vue.extend({
  name: 'EmptySearch',
  props: {
    isRootFolder: Boolean,
  },
})
